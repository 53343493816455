import api from '@/api/index'
// axios
import request from '@/utils/request'

// 获取会员方案方法
export function getVipPlan () {
  return request({
    url: api.GetVipPlan,
    method: 'GET',
    hideloading: false
  })
}

// 获取可用活动方法
export function getActivityList () {
  return request({
    url: api.GetActivity,
    method: 'GET',
    hideloading: false
  })
}

// 获取可用活动优惠券方法
export function getCouponInfoByActivityId (params) {
  return request({
    url: api.GetCouponInfoByActivityId,
    method: 'GET',
    params,
    hideloading: false
  })
}

// 领取活动优惠券方法
export function receiveCoupon (data) {
  return request({
    url: api.ReceiveCoupon,
    method: 'post',
    data,
    hideloading: false
  })
}

// 领取活动优惠券方法
export function getCouponsByModel (params) {
  return request({
    url: api.GetCouponsByModel,
    method: 'get',
    params,
    hideloading: false
  })
}

// 报告统一下单
export function createOrder (data) {
  return request({
    url: api.CreateOrder,
    method: 'post',
    data,
    hideloading: true
  })
}

// 获取支付信息成功失败
export function queryOrder (data) {
  return request({
    url: api.Queryorder,
    method: 'post',
    data,
    hideloading: true
  })
}

// 查询会员订单在支付状态
export function getVipPayState (params) {
  return request({
    url: api.GetVipPayState,
    method: 'get',
    params,
    hideloading: false
  })
}
