<template>
  <div>
    <div class="img-content">
      <el-image fit="fill" style="height: 600px;width: 100%;" draggable="false" :src="bg"></el-image>
      <!-- 开通会员 -->
      <div class="container" style="margin-top: -400px;margin-bottom: 50px;">
        <div id="top" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
        <div style="font-size: 46px;color: #E7C478;margin-bottom: 30px;">成为VIP会员，享受更多特权服务</div>
        <el-carousel v-if="swiperList" indicator-position="none"  pause-on-hover="false" arrow="never" :autoplay="autoplay" @change="handleChaneSwiper" interval="2000" type="card" height="440px">
          <el-carousel-item v-for="(item, index) in swiperList" :key="index">
            <div class="plan-content">
              <div style="color: #FC7A1E;font-size: 30px;width: 100%;font-weight: bold;margin-top: 40px;">VIP会员</div>
              <div style="color: #666;font-size: 24px;width: 100%;font-weight: bold;margin-top: 65px;">{{item.planName}}</div>
              <div style="margin-top: 30px;">
                <span style="font-size: 18px;font-weight: bold;color: #FC7A1E;">¥</span>
                <span style="font-size: 48px;color: #FC7A1E;font-weight: bold;">{{item.payablePrice}}</span>
                <span v-if="index == activeSwiper" style="color: #666666;font-size: 14px;text-decoration:line-through;margin-left: 20px;font-weight: bold;">¥{{item.originalPrice}}</span>
              </div>
              <div class="btn-open" @click="btnStatus ? '' : handleOpenVip() ">立即开通</div>
              <div v-if="index == activeSwiper" style="width: 100%;font-size: 12px;color: #666666;margin-top: 10px;">
                <el-checkbox v-model="checked"></el-checkbox>
                <span style="margin-left: 10px;">我已阅读并同意<span @click="agreementClick()" style="color: #FC7A1E;">《E查工程会员服务协议》</span></span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="container" v-show="showContent">
        <!-- 会员介绍 -->
        <el-affix :offset="60">
          <el-image @click="returnTop('top')" fit="fill" style="width: 100%;" draggable="false" :src="vip1"></el-image>
        </el-affix>
        <el-image fit="contain" style="width: 100%;" draggable="false" :src="vip2"></el-image>
        <el-image fit="contain" draggable="false" :src="vip3"></el-image>
        <el-image fit="contain" draggable="false" :src="vip4"></el-image>
        <el-image fit="contain" draggable="false" :src="vip6"></el-image>
        <el-image fit="contain" draggable="false" style="margin-bottom: 30px;"  :src="vip5"></el-image>
      </div>
    </div>
   <el-dialog title="微信支付" width="60%" append-to-body="true" v-model="dialogTableVisible">
      <el-row style="border-top: 1px solid #ebebeb;margin-bottom: 20px;padding-top: 20px;">
        <el-col :span="12" class="font-pay">当前账户：{{userMes.mobile}}</el-col>
        <el-col :span="12" class="font-pay">开通时长：{{swiperList[activeSwiper].timeLength}}{{swiperList[activeSwiper].timeLengthTypeUnit}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="font-pay">支付金额：<span style="font-size: 24px;color: #FC7A1E;">¥{{swiperList[activeSwiper].payablePrice}}</span></el-col>
      </el-row>
      <el-row style="margin: 20px 0;">
        <el-col :span="24" class="dis-aro-cen" style="background-color: #f5f5f9;">
          <div id="qrcode" ref="qrcode"></div>
          <el-image fit="contain" style="width: 300px;margin-right: 50px;" draggable="false" :src="pay2"></el-image>
        </el-col>
      </el-row>
    </el-dialog>
<!--    <el-dialog title="获取更多特权" width="60%" append-to-body="true" v-model="dialogTableVisible">
      <el-row style="border-top: 1px solid #ebebeb;margin-bottom: 20px;padding-top: 20px;">
        <el-col :span="24" class="font-pay">使用<span style="color: red;">手机浏览器</span>扫码获取更多特权，如有疑问请拨打客服电话：{{customerServiceTel || '400-8885449'}}</el-col>
      </el-row>
      <el-row style="margin: 20px 0;">
        <el-col :span="24" class="dis-cen-cen" style="background-color: #f5f5f9;padding: 30px 0;">
          <el-image fit="contain" style="width: 300px;margin-right: 50px;" draggable="false" :src="h5bg"></el-image>
        </el-col>
      </el-row>
    </el-dialog> -->
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapGetters } from 'vuex'
import { getVipPlan, getVipPayState, createOrder } from '@/api/vip.js'
import { getByTel } from '@/api/user.js'
import { ElMessage } from 'element-plus'
export default {
  name: 'vip-index',
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  // components: { QRCode },
  data () {
    return {
      checked: true,
      dialogTableVisible: false,
      autoplay: false,
      islogin: false,
      h5bg: require('@/assets/images/h5.png'),
      pay: require('@/assets/vip/pay.png'),
      pay2: require('@/assets/vip/pay2.png'),
      bg: require('../../assets/index/bg.png'),
      vip1: require('@/assets/vip/vip1.png'),
      vip2: require('@/assets/vip/vip2.png'),
      vip3: require('@/assets/vip/vip3.png'),
      vip4: require('@/assets/vip/vip4.png'),
      vip5: require('@/assets/vip/vip5.png'),
      vip6: require('@/assets/vip/vip6.png'),
      swiperList: null,
      activeSwiper: 0,
      showContent: false,
      btnStatus: false,
      userMes: {},
      timers: null,
      orderNum: ''
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'customerServiceTel'
    ])
  },
  created () {
    this.initVipPlan()
    if (this.idToken) {
      this.islogin = false
    } else {
      this.islogin = true
    }
  },
  watch: {
    dialogTableVisible: {
      handler (val, oldVal) {
        if (val === false) {
          this.btnStatus = false
          if (this.timers) {
            clearInterval(this.timers)
            this.timers = ''
          }
        }
      }
    }
  },
  mounted () {
    const vm = this
    if (this.userInfo) {
      vm.userMes = JSON.parse(this.userInfo)
    }
    setTimeout(function () {
      vm.showContent = true
    }, 100)
  },
  methods: {
    // 开通会员
    handleOpenVip () {
      const vm = this
      if (vm.idToken) {
        if (vm.checked === false) {
          ElMessage.warning({
            message: '请阅读并勾选用户协议',
            offset: 60
          })
        } else {
          vm.btnStatus = true
          const plan = vm.swiperList[vm.activeSwiper]
          const data = {
            userId: vm.userMes.userId,
            contactInfo: vm.userMes.mobile,
            customerName: vm.userMes.realName === null || vm.userMes.realName === '' ? vm.userMes.mobile : vm.userMes.realName,
            orderPrice: plan.payablePrice,
            blVipPlanId: plan.blVipPlanId,
            body: plan.planName,
            price: plan.payablePrice * 100
          }
          createOrder(data).then(request => {
            console.log(request)
            if (request.code === 0) {
              vm.dialogTableVisible = true
              const res = request.data
              vm.orderNum = res.orderNum
              if (res) {
                vm.$nextTick(function () {
                  vm.qrcode(res)
                })
              }
            }
          })
          console.log(data)
        }
      } else {
        this.loginEmit()
      }
    },
    // 生成二维码
    qrcode (e) {
      document.getElementById('qrcode').innerHTML = ''
      const qrcode = new QRCode('qrcode', {
        width: 232, // 设置宽度
        height: 232, // 设置高度
        text: e.code_url
      })
      console.log(qrcode)
      this.getOrderStatus()
    },
    // 定时器
    getOrderStatus () {
      const vm = this
      let num = 0
      vm.timers = setInterval(() => {
        num++
        console.log(num)
        if (num >= 500) {
          vm.dialogTableVisible = false
          if (vm.timers) {
            clearInterval(vm.timers)
            vm.timers = ''
          }
        }
        const data = {
          orderNum: vm.orderNum
        }
        getVipPayState(data).then(request => {
          console.log(request)
          if (request.code === 0) {
            vm.dialogTableVisible = false
            if (vm.timers) {
              clearInterval(vm.timers)
              vm.timers = ''
            }
            ElMessage.success(request.msg)
            getByTel({ tel: vm.userMes.mobile }).then(result => {
              if (result.code === 0) {
                vm.$store.dispatch('setVipData', JSON.stringify(result.data))
              }
            }).catch(error => {
              console.log(error)
            })
          }
        })
      }, 1500)
    },
    returnTop (e) {
      document.querySelector('#' + e).scrollIntoView()
    },
    initVipPlan () {
      const vm = this
      getVipPlan().then(result => {
        if (result.code === 0) {
          const arr = []
          if (result.data.length > 0) {
            result.data.forEach(item => {
              if (item.timeLengthType === 0) {
                item.timeLengthTypeUnit = '年'
              } else if (item.timeLengthType === 1) {
                item.timeLengthTypeUnit = '月'
              } else if (item.timeLengthType === 2) {
                item.timeLengthTypeUnit = '周'
              } else if (item.timeLengthType === 3) {
                item.timeLengthTypeUnit = '日'
              }
              arr.push(item)
            })
          }
          vm.swiperList = arr
        }
      })
    },
    handleChaneSwiper (e) {
      console.log(e)
      this.activeSwiper = e
    },
    agreementClick () {
      const vm = this
      sessionStorage.removeItem('about-item')
      sessionStorage.setItem('about-item', 'vipServiceAgreement')
      const routeData = vm.$router.resolve({
        name: 'about'
      })
      window.open(routeData.href, '_blank')
    }
  },
  beforeumount () {
    const vm = this
    if (vm.timers) {
      clearInterval(vm.timers)
      vm.timers = ''
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.img-content {
  margin-top: -60px;
  min-width: 1200px;
  position: relative;
}
.plan-content {
  display: flex;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: url(../../assets/vip/planbg.png) no-repeat;
  background-size: 100% 100%;
}
.el-carousel__mask {
  background-color: rgba(0,0,0,0);
}
.btn-open {
  width: 260px;
  background-color: #FC7A1E;
  color: #FFFFFF;
  font-size: 16px;
  margin: 0 auto;
  border-radius: 4px;
  height: 50px;
  line-height: 50px;
  margin-top: 30px;
}
.el-image {
  position: initial;
  display: block;
}
.el-carousel__item--card {
  width: 28%;
  margin: 0 10%;
}
.font-pay{
  font-size: 16px;
  color: #666;
}
</style>
